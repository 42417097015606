<template>
  <v-dialog
    v-model="show"
    max-width="800"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title>Resend Checkout Mail (with receipt)</v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            v-model="form.email"
            label="Recipient Email"
          >
          </v-text-field>

          <v-btn
            :loading="loading"
            color="primary"
            @click="handleSubmit"
          >
            Send
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { notifyCheckout } from '@/api/order'
import { useNotifyErrors } from '@/composables'
import { ref } from '@vue/composition-api'

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    checkoutId: {
      type: Number,
      required: true,
    },
    defaultEmail: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const loading = ref(false)
    const form = ref({
      email: props.defaultEmail,
    })

    const handleSubmit = () => {
      loading.value = true
      notifyCheckout(props.checkoutId, form.value)
        .then(() => {
          emit('close')
          emit('created')
        })
        .catch(useNotifyErrors)
        .finally(() => {
          loading.value = false
        })
    }

    return {
      form,
      loading,
      handleSubmit,
    }
  },
}
</script>
