<template>
  <v-dialog
    v-model="show"
    @click:outside="$emit('close')"
  >
    <refund-request-form
      :checkout-id="checkoutId"
      :loading="loading"
      @submit="handleSubmit"
      @close="$emit('close')"
    >
    </refund-request-form>
  </v-dialog>
</template>

<script>
import { storeRefundRequest } from '@/api/order'
import { useNotifyErrors } from '@/composables'
import { ref } from '@vue/composition-api'
import RefundRequestForm from './RefundRequestForm.vue'

export default {
  components: { RefundRequestForm },

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    checkoutId: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const loading = ref(false)

    const handleSubmit = form => {
      console.log('form is', form)
      loading.value = true
      storeRefundRequest(form)
        .then(() => {
          emit('close')
          emit('created')
        })
        .catch(useNotifyErrors)
        .finally(() => {
          loading.value = false
        })
    }

    return {
      loading,
      handleSubmit,
    }
  },
}
</script>
