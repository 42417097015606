<template>
  <v-card>
    <v-card-title v-if="checkoutData">
      Requesting Checkout, paid at {{ checkoutData.total | localPrice({ currency: checkoutData.currency }) }}
    </v-card-title>

    <v-card-text v-if="checkoutData">
      <v-form ref="formElem">
        <h4>General Info</h4>
        <p>Customer Paid: {{ checkoutData.total | localPrice({ currency: checkoutData.currency }) }}</p>
        <v-divider />
        <p>Items worth: {{ checkoutData.subtotal | localPrice({ currency: checkoutData.currency }) }}</p>
        <p v-if="checkoutData.coupons_total > 0">
          Coupon: -{{ checkoutData.coupons_total | localPrice({ currency: checkoutData.currency }) }}
        </p>
        <p v-if="checkoutData.discount_total > 0">
          Discount: -{{ checkoutData.discount_total | localPrice({ currency: checkoutData.currency }) }}
        </p>
        <p>Credits: {{ checkoutData.credits_total | localPrice({ currency: checkoutData.currency }) }}</p>
        <p>Shipping Fee (paid): {{ checkoutData.shipping_total | localPrice({ currency: checkoutData.currency }) }}</p>

        <h4 class="mt-5">
          Step 1. Choose at least one Order Item
        </h4>
        <v-data-table
          v-model="selectedItems"
          :headers="orderItemColumns"
          show-select
          :items="orderItems"
          class="text-no-wrap mt-5"
          :hide-default-footer="true"
          :items-per-page="99"
        >
          <template #[`item.total`]="{item}">
            <!-- {{ item.total | localPrice({ currency: checkoutData.currency }) }} -->
            <v-text-field
              v-model="item.$total"
              :label="` max ${item.total}`"
              :rules="[isSelected(item) ?between(item.$total, 1, item.total) : true]"
              :disabled="!isSelected(item)"
            >
            </v-text-field>
          </template>
          <template #[`item.quantity`]="{item}">
            <v-text-field
              v-model="item.$quantity"
              :label="`Quantity - max ${item.quantity}`"
              :rules="[isSelected(item) ?between(item.$quantity, 1, item.quantity) : true]"
              :disabled="!isSelected(item)"
            >
            </v-text-field>
          </template>
          <template #[`item.item_total`]="{item}">
            {{ item.total | localPrice({ currency: checkoutData.currency }) }}
            <span v-show="item.refund_total > 0">(already refunded {{ item.refund_total | localPrice({ currency: checkoutData.currency }) }})</span>
          </template>
          <template #[`item.sku`]="{item}">
            {{ t(item.sku.name) }} -
            {{ t(item.product.title) }}
          </template>
        </v-data-table>

        <h4 class="mt-5">
          Step 2. Choose Credits to refund
        </h4>
        <v-data-table
          v-model="selectedCredits"
          :headers="creditColumns"
          show-select
          :items="creditItems"
          class="text-no-wrap mt-5"
          :hide-default-footer="true"
        >
          <template #[`item.total`]="{item}">
            <v-text-field
              v-model="item.$total"
              :label="`max ${item.total}`"
              :rules="[isSelectedCredit(item) ?between(item.$total, 1, item.total) : true]"
              :disabled="!isSelectedCredit(item)"
            >
            </v-text-field>
            <!-- {{ item.total | localPrice({ currency: checkoutData.currency }) }} -->
          </template>
          <template #[`item.quantity`]="{item}">
            <v-text-field
              v-model="item.$quantity"
              :label="`Credits / Points - max ${item.credits_amount}`"
              :rules="[isSelectedCredit(item) ?between(item.$quantity, 1, item.credits_amount) : true]"
              :disabled="!isSelectedCredit(item)"
            >
            </v-text-field>
          </template>
          <template #[`item.item_total`]="{item}">
            {{ item.total | localPrice({ currency: checkoutData.currency }) }}
            <span v-show="item.refund_total > 0">(already refunded {{ item.refund_total | localPrice({ currency: checkoutData.currency }) }})</span>
          </template>
          <template #[`item.sku`]="{item}">
            {{ item.key }}
          </template>
        </v-data-table>

        <h4 class="mt-5">
          Step 3. Shipping Fee refund (max.{{ checkoutData.shipping_total | localPrice({ currency: checkoutData.currency }) }})
        </h4>
        <p>This value is set to notify how much shipping fee you'd refund to the customer.</p>
        <v-text-field
          v-model="form.refund_shipping_total"
          label="Shipping Total Refund Amount"
          :rules="[between(form.refund_shipping_total, 0, checkoutData.shipping_total)]"
        >
        </v-text-field>

        <h4 class="mt-5">
          Step 4. Refund Cash / Credit Card - (Paid {{ checkoutData.total | localPrice({ currency: checkoutData.currency }) }}) (Credit {{ checkoutData.credits_total | localPrice({ currency: checkoutData.currency }) }})
        </h4>
        <p>This value has <b>included the shipping fee</b>, and topped at maximum of how much customer paid using our payment system.</p>
        <v-text-field
          v-model="form.refund_total"
          label="Requested refund amount"
          :rules="[between(form.refund_total, 0, refundable)]"
        >
        </v-text-field>

        <h4 class="mt-5">
          Optional: Refund reason
        </h4>
        <v-text-field
          v-model="form.reason"
          label="Refund Reason"
        >
        </v-text-field>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <template>
        <v-btn
          :loading="loading"
          color="success"
          :disabled="!selectedItems.length"
          @click="validate"
        >
          Submit Request
        </v-btn>
        <v-btn
          :loading="loading"
          @click="$emit('close')"
        >
          Back
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import { t } from '@/plugins/i18n'
import { localPrice } from '@core/utils/filter'
import { between } from '@core/utils/validation'
import { computed, ref, watch } from '@vue/composition-api'

// import useRefundRequest from './useRefundRequest'
import useCheckoutView from '../checkout-view/useCheckoutView'

export default {
  filters: { localPrice },
  props: {
    checkoutId: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { loadCheckout, checkoutData } = useCheckoutView()

    loadCheckout(props.checkoutId).then(() => {
      form.value.refund_shipping_total = checkoutData.value.shipping_total
    })

    const orderItemColumns = [
      { text: `Refund ${checkoutData.currency}`, value: 'total' },
      { text: 'Quantity', value: 'quantity' },
      { text: 'Items Total', value: 'item_total' },
      { text: 'STATUS', value: 'status' },
      { text: 'SKU', value: 'sku' },
    ]

    const creditColumns = [
      { text: `Refund ${checkoutData.currency}`, value: 'total' },
      { text: 'Credit / Point', value: 'quantity' },
      { text: 'Items Total', value: 'item_total' },
      { text: 'Credit Type', value: 'sku' },
    ]

    const orderItems = computed(() => {
      if (!checkoutData.value) return []
      let ois = []
      checkoutData.value.orders.forEach(order => {
        ois = ois.concat(order.items)
      })

      return ois
    })

    const creditItems = computed(() => {
      if (!checkoutData.value) return []

      return checkoutData.value.credits
    })

    const selectedItems = ref([])
    const selectedCredits = ref([])
    const formElem = ref(null)

    const initialForm = {
      order_items: [],
      refund_total: 0,
      refund_shipping_total: 0,
      payment_id: null,
      reason: null,
    }

    const form = ref({ ...initialForm })

    const refundable = computed(() => {
      if (!checkoutData.value) return 0

      return checkoutData.value.total - checkoutData.value.refund_total
    })

    const validate = () => {
      form.value.items = selectedItems.value
        .map(item => ({
          id: item.id,
          quantity: item.$quantity,
          type: 'order_item',
          total: item.$total,
        }))
        .concat(
          selectedCredits.value.map(item => ({
            id: item.id,
            quantity: item.$quantity,
            type: 'credit',
            total: item.$total,
          })),
        )
      form.value.payment_id = checkoutData.value.payments[0].id

      if (formElem.value.validate()) emit('submit', form.value)
    }

    watch([selectedItems, computed(() => form.value.refund_shipping_total)], () => {
      form.value.refund_total = 0
      selectedItems.value.forEach(item => {
        form.value.refund_total += item.total - item.refund_total
      })
      selectedCredits.value.forEach(item => {
        form.value.refund_total -= item.total - item.refund_total
      })
      form.value.refund_total += parseFloat(form.value.refund_shipping_total)
    })

    const isSelected = item => selectedItems.value.find(i => i.id === item.id)
    const isSelectedCredit = credit => selectedCredits.value.find(i => i.id === credit.id)

    return {
      orderItemColumns,
      creditColumns,
      checkoutData,
      orderItems,
      creditItems,
      selectedItems,
      selectedCredits,
      t,

      formElem,
      form,
      between,
      validate,

      refundable,

      isSelected,
      isSelectedCredit,
    }
  },
}
</script>
