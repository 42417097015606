<template>
  <v-card v-if="items.length">
    <v-card-title>Refund Requests</v-card-title>

    <v-card-text>
      <v-row>
        <v-col
          v-for="req in items"
          :key="req.id"
          :cols="4"
        >
          <p>
            <span>{{ req.total | price }}</span>
            <v-chip
              small
              class="ml-2"
            >
              {{ req.status }}
            </v-chip>
          </p>
          <p>
            <small v-if="req.credits_total > 0">credit: {{ req.credits_total | price }}</small>
          </p>

          <p>
            {{ req.reason }}
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { price } from '@core/utils/filter'

export default {
  filters: {
    price,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>
