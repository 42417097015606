var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.items,"items-per-page":99,"show-select":false,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type)+" ")]}},{key:"item.outcome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.outcome)+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [(item.user_type)?_c('span',[_vm._v(_vm._s(_vm.mapType(item.user_type))+" "+_vm._s(item.user_id))]):_vm._e()]}},{key:"item.remarks",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.remarks || 'n/a')+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTime")(item.created_at))+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }