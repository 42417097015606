import { useNotifyErrors, useNotifySuccess } from '@/composables'
import { fetchOrder, resendCheckoutEvent, updateOrder } from '@api/order'
import { ref } from '@vue/composition-api'

export default function useOrderView() {
  const orderData = ref(null)
  const loading = ref(false)

  // fetch data
  const loadOrder = id => {
    orderData.value = null
    loading.value = true

    return fetchOrder(id)
      .then(res => {
        orderData.value = res.data.data

        return res
      })
      .catch(useNotifyErrors)
      .finally(() => {
        loading.value = false
      })
  }

  // update/touch data, for event resending
  const touchOrder = id => updateOrder(id).then(() => useNotifySuccess({ content: 'pending to resend' }))

  const touchCheckoutEvent = (id, type) => resendCheckoutEvent(id, type).then(() => useNotifySuccess({ content: 'pending to resend' }))

  return {
    loading,

    orderData,

    loadOrder,
    touchOrder,
    touchCheckoutEvent,
  }
}
