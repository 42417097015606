<template>
  <div class="mb-7">
    <v-container
      v-if="order.nature === 'tangible'"
      fluid
    >
      <v-card
        v-for="shipping in order.shippings"
        :key="shipping.id"
        class="mx-auto"
        outlined
      >
        <v-card-title>Shipping Destination</v-card-title>
        <v-card-text>
          <v-chip>status: {{ shipping.status }}</v-chip>
          <p>Address: {{ shipping.address }}</p>
          <p>Remarks: {{ shipping.destination.remarks }}</p>
          <p>Recipient: {{ shipping.destination.first_name }}, {{ shipping.destination.last_name }}</p>
          <p>Contact Number: {{ shipping.destination.contact_number }}</p>
          <p>Fee Payment: {{ shipping.fee_payment }}</p>
        </v-card-text>
      </v-card>
    </v-container>

    <div
      v-for="item in order.items"
      :key="item.uuid"
    >
      <template v-if="order.nature === 'voucher'">
        <v-container
          v-for="(voucher, index) in item.vouchers"
          :key="voucher.code"
          fluid
        >
          <v-card
            class="mx-auto"
            outlined
          >
            <v-card-title>Voucher: # {{ index + 1 }}</v-card-title>
            <v-card-text>
              <v-chip>status: {{ voucher.status }}</v-chip>
              <router-link
                class="font-weight-semibold text-decoration-none"
                :to="{ name: 'product-view', params: { id: item.product_id }, query: { tab: 'VOUCHERS' } }"
              >
                code: {{ voucher.code }}
              </router-link>
            </v-card-text>
          </v-card>
        </v-container>
      </template>

      <v-container
        v-if="item.participants.length"
        fluid
      >
        <h3>{{ t(item.sku_snapshot.sku_name) }}</h3>

        <v-card
          v-for="(participant,index) in item.participants"
          :key="participant.uuid"
          class="mx-auto"
          outlined
          cols="6"
        >
          <v-card-title>Participant: # {{ index + 1 }}</v-card-title>
          <v-card-text>
            <h3>Basic Info</h3>
            <div>Name: {{ participant.first_name }} {{ participant.last_name }}</div>
            <div>Email: {{ participant.email }}</div>
            <div>Phone: {{ participant.phone }}</div>
            <div>Gender: {{ participant.gender }}</div>

            <template v-if="participant.pivot.snapshot">
              <h3>Checkout provided Info</h3>
              <div
                v-for="[key, value] in Object.entries(JSON.parse(participant.pivot.snapshot))"
                :key="key"
              >
                {{ key }}: <b>{{ value }}</b>
              </div>
            </template>
            <v-alert
              v-if="participant.pivot.remarks"
              color="error"
              text
            >
              Remarks: {{ participant.pivot.remarks }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-container>

      <v-container
        v-if="item.membership_group"
        fluid
      >
        <h2>Membership</h2>
        <p>{{ t(item.sku_snapshot.sku_name) }}</p>

        <v-card
          v-for="(membership) in item.membership_group.memberships"
          :key="membership.id"
          class="mx-auto"
          outlined
          cols="6"
        >
          <v-card-title>Membership: # {{ membership.id }}</v-card-title>
          <v-card-text>
            <p>
              Next Billing at: {{ membership.next_billing_at | dateSimple }}
              ({{ membership.next_billing_at | diff('days') }} days)
            </p>
            <template v-if="membership.user">
              <p>Email: {{ membership.user.email }}</p>
              <p>Phone: {{ membership.user.phone }}</p>
            </template>
            <template v-else>
              This membership is not yet started/redeemed.
            </template>
          </v-card-text>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import { dateSimple, diff } from '@core/utils/filter'
import { mdiPlus } from '@mdi/js'

export default {
  filters: { dateSimple, diff },

  props: {
    order: { type: Object, required: true },
  },

  setup() {
    return {
      t,
      icons: {
        mdiPlus,
      },
    }
  },
}
</script>
